import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

import { environment } from './../../environments/environment';
import isEmpty from 'lodash-es/isEmpty';
// import eq from 'lodash-es/eq';



@Injectable()
export class AuthService {
    routeParams: any;
    // product: any;
    onUserChanged: BehaviorSubject<any>;
    user: any = {};

    /**
     * Constructor
     *
     * @param _router
     * @param {HttpClient} _httpClient
     * @param _authService
     */
    constructor(
        private _router: Router,
        private _httpClient: HttpClient,
        private _authService: JwtHelperService,
    ) {
        // Set the defaults
        this.onUserChanged = new BehaviorSubject(this.user);
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    //     this.routeParams = route.params;
    //     return new Promise((resolve, reject) => {
    //
    //         Promise.all([
    //             this.getProduct()
    //         ]).then(
    //             () => {
    //                 resolve();
    //             },
    //             reject
    //         );
    //     });
    // }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    // getProduct(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         if (this.routeParams.id === 'new') {
    //             this.onProductChanged.next(false);
    //             resolve(false);
    //         }
    //         else {
    //             this._httpClient.get('api/e-commerce-products/' + this.routeParams.id)
    //                 .subscribe((response: any) => {
    //                     this.product = response;
    //                     this.onProductChanged.next(this.product);
    //                     resolve(response);
    //                 }, reject);
    //         }
    //     });
    // }

    /**
     * Save product
     *
     * @param product
     * @returns {Promise<any>}
     */
    auth(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.api.security}/auth/login`, user)
                .subscribe((response: any) => {
                    localStorage.setItem('token', response.token)
                    this.user = this._authService.decodeToken();
                    console.log('this', this.user);
                    // this.onUserChanged.next(this.user);
                    resolve(this.user);
                }, reject);
        });
    }


    getUser(): Observable<any> {
        // if (isEmpty(this.user) || eq(this.user, this.user)) {
        // if (isEmpty(this.user)) {
        // console.log(this._authService.decodeToken());
        this.user = this._authService.decodeToken();
        this.onUserChanged.next(this._authService.decodeToken())
        // };
        return this.user;
        // return this.onUserChanged;
    }

    logout(next = ''): void {
        localStorage.removeItem('token');
        this._router.navigate(['/'], { queryParams: {next: next}});
    }

}
