import { Injectable } from '@angular/core';
// import * as _ from 'lodash';
// import { HttpClient, HttpParams } from '@angular/common/http';
// import { CategoryEntity } from 'entities'
import { Observable, BehaviorSubject } from 'rxjs';
// import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    onToastSend: BehaviorSubject<any>;


    constructor(
        // private _httpClient: HttpClient
    ) {
        // Set the defaults
        this.onToastSend = new BehaviorSubject({});
    }

    send(data) {
        this.onToastSend.next(data)
    }

    // create(device: any) {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post(`https://techpre-ipam.mybluemix.net/devices`, device)
    //             .subscribe((response: any) => {
    //                 this.onDevicesChanged.next(response);
    //                 resolve(response)
    //             }, reject)
    //     })
    // }
    /**
     * Get Categories
     *
     * @returns {Promise<any>}
     */
    // get(id: string = '', query: any = {}): Promise<any> {
    //     if (typeof query.where === 'object') query.where = JSON.stringify(query.where);
    //     const params = new HttpParams({ fromObject: query });
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.get(`${environment.api.systems}/applications/${id}`, { params })
    //             .subscribe((response: any) => {
    //                 // this.onDevicesChanged.next(response);
    //                 resolve(response);
    //             }, reject);
    //
    //     });
    // }

    // getParentCategories(query: any): Promise<[CategoryEntity]> {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.get(`api/categories?isParent=${query.isParent}`)
    //             .subscribe((response: any) => {
    //                 // this.product = response;
    //                 // this.onProductChanged.next(this.product);
    //                 resolve(response);
    //             }, reject);
    //     });
    // }

    // updateCategory(category) {
    //     let id = category._id;
    //     delete category._id
    //
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.put(`http://localhost:3010/categories/${id}`, category)
    //             .subscribe((response: any) => {
    //                 // this.onCategoryCreated.next(response);
    //                 resolve(response)
    //             }, reject)
    //     });
    //
    // }

    // delete(id: string) {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.delete(`https://techpre-ipam.mybluemix.net/devices/${id}`)
    //             .subscribe((response: any) => {
    //                 this.onDevicesChanged.next(response);
    //                 // PENDIENTE: Cada vez que se elimine una categoria se llamara
    //                 // a este evento, hasta este momento solo se usa para
    //                 // refrezcar despues de que se crea una categoria
    //                 // y lo usamos aqui para que se actualize una vez que se elimine
    //                 // this.onCategoryCreated.next(response);
    //                 resolve(response)
    //             }, reject)
    //     });
    //
    // }



}
