import {Injectable, Inject, InjectionToken} from '@angular/core';
import {connect, jwtAuthenticator, credsAuthenticator} from "nats.ws";
import {decode} from 'cbor-x/decode'
import {encode} from 'cbor-x/encode'

declare const msgpack5;


/**
 * Crear un metodo autoOff, que apagara la escucha en los eventos, el objetivo de esto es que Angular cada vez que entre a la vista
 * agregara un nuevo Listener sin eliminar los viejos llevanto a events sin necesidad, para ellos crearemos un identificador unico
 * antes de agregar el listener entonces de esta forma todos los listeners agregados a este itentidficador cuando se corra
 * AutoOff se eliminaran todos cuando se desee,
 * */

@Injectable()
export class Hermes2Service {
    // Array de servidores Nats
    servers = ["wss://hermes.techpre.io"];
    // Conexion a nats;
    conn;

    private _config: any = {};

    constructor() {
    }

    setInitialization(config = {}): Promise<void> {
        this._config = config;
        return new Promise(async (resolve, reject) => {
            this.connect()
                .then(resolve)
                .catch(reject)
        });
    }

    async connect(_creds= '') {
        this.conn = await connect({
                servers: this.servers,
                authenticator: credsAuthenticator(new TextEncoder().encode(this._config.creds)),
            },
        );
    }

    emit(channel, data) {
        this.conn.publish(channel, encode(data));
    }

    on(channel, options, cb) {
        console.log('ON:', `[${channel}]`, options)

        return this.conn.subscribe(channel, {
            ...options,
            callback: (err, msg) => {
                const message = Object.assign({}, msg, {data: decode(msg.data)})
                if (msg.reply.length) cb(err, message, msg)
                else cb(err, message)
            }
        });
    }

    request(channel, data = {}, options = {timeout: 20000}) {
        data = encode(data)
        return new Promise((resolve, reject) => {
            this.conn.request(channel, data, options)
                .then(msg => {
                    resolve(decode(msg.data))
                })
                .catch(reject)
        })
    }

}