import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@fuse/services/auth.service';
import { ToastService } from '@fuse/services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private _eventToastr: ToastService,
        private _authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.error(err);
            if ([404].includes(err.status)) {
                // console.log('Recurso no encontrado');
                this._eventToastr.send({ type: 'error', message: `Recurso no encontrado`, title: 'No encontrado' })
            }

            if ([401, 403].includes(err.status)) {
                // auto logout if 401 or 403 response returned from api
                // this.authenticationService.logout();
                this._eventToastr.send({ type: 'error', message: `Acceso no Autorizado`, title: 'Sin permiso' })
                this._authService.logout();
            }

            if ([400].includes(err.status)) {
                if (err.error.name === 'ValidationError') { this.onValidationError(err.error); }
                if (err.error.name === 'UserNotFound') { this.onUserNotFound(); }
            }



            const error = (err && err.error && err.error.message) || err.statusText;
            console.error(error);

            return throwError(error);
        }));
    }

    onValidationError(err: any): void {
        //     // Type: ToastrService.success/error/warning/info/show()
        //     this._eventToastr.send({ type: 'success', message: 'Hello world!', title: 'Toastr fun!' })
        if (err.payload.type === 'unique') {
            if (err.payload.path === 'email') this._eventToastr.send({ type: 'error', message: `El correo ${err.payload.value} se encuentra repetido`, title: 'Correo repetido' })
        }

        if (err.message.includes('`rif` is required')) {
            this._eventToastr.send({ type: 'error', message: `El RIF es requerido`, title: 'Campo requerido' })
        }

        if (err.message.includes('to be unique')) {
            if (err.message.includes('dni')) this._eventToastr.send({ type: 'error', message: `Cedula repetida`, title: 'Campo repetido' })
            if (err.message.includes('rif')) this._eventToastr.send({ type: 'error', message: `RIF repetido`, title: 'Campo repetido' })
        }
    }

    onUserNotFound(): void {

        this._eventToastr.send({ type: 'error', message: `Usuario no encontrado`, title: 'Negativo' });

    }
}
