import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@fuse/services/auth.service';

@Injectable()
export class CanActivateAuthGuard implements CanActivateChild {

    constructor(
        private authService: JwtHelperService,
        private _authService: AuthService

    ) {

    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url = state.url;
        if (this.authService.isTokenExpired()) {
            this._authService.logout(url);
            return false;
        }
        return true;
    }
}
