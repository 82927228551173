import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
// import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from "@ngx-translate/core";
import { JwtModule } from "@auth0/angular-jwt";
import { FuseModule } from "@fuse/fuse.module";
import { ErrorInterceptor } from "@fuse/interceptor/error.interceptor";
import { ToastrModule } from "ngx-toastr";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    // FuseThemeOptionsModule,
    // CategoryNavModule
} from "@fuse/components";
import { CanActivateAuthGuard } from "@fuse/guards/auth.guard";
import { CanLoadAuthGuard } from "@fuse/guards/canLoad/auth.guard";

import { fuseConfig } from "app/fuse-config";

// import { FakeDbService } from 'fake-db/fake-db.service';
import { MainComponent } from "app/app.component";
// import { AppStoreModule } from 'app/store/store.module';
import { environment } from "./../environments/environment";
import { registerLocaleData } from "@angular/common";
import es_VE from "@angular/common/locales/es-VE";

import { AuthService } from "@fuse/services/auth.service";
import { HermesService } from "./hermes/hermes.service";
import { Hermes2Service } from "./hermes/hermes2.service";

export function tokenGetter(): any {
    return localStorage.getItem("token");
}

// export function init_app(hermesService: HermesService): () => Promise<void> {
//     return () =>
//         hermesService.setInitialization({
//             token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MX0.tW0nFSfjHWttDIlDOM2TSQIRNZOtWPgb9pz7S2TOchw",
//         });
// }

export function init_app2(hermesService2: Hermes2Service): () => Promise<void> {
    return () =>
        hermesService2.setInitialization({ creds: environment.HERMES_CREDS });
}

const appRoutes: Routes = [
    {
        path: "auth",
        loadChildren: () =>
            import("./main/auth/auth.module").then((m) => m.AuthModule),
    },
    {
        path: "apps",
        title: "TECHPRE® - Technology for All",
        loadChildren: () =>
            import("./main/apps/apps.module").then((m) => m.AppsModule),
        canActivateChild: [CanActivateAuthGuard],
        // canLoad: [CanLoadAuthGuard]
    },
    {
        path: "qr",
        title: "TECHPRE® - Technology for All",
        loadChildren: () =>
            import("./main/qr/qr.module").then((m) => m.QrModule),
    },

    {
        path: "**",
        redirectTo: "auth/login",
    },
];

registerLocaleData(es_VE, "es-VE");

@NgModule({
    declarations: [MainComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),
        TranslateModule.forRoot(),
        JwtModule.forRoot({
            config: {
                authScheme: "JWT ",
                tokenGetter: tokenGetter,
                allowedDomains: ["cuado.co:444", "techpre.io"],
                disallowedRoutes: ["https://cuado.co:444/api/v1"],
                // throwNoTokenError: true,
                skipWhenExpired: true,
            },
        }),
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        // FuseProgressBarModule,
        // FuseSharedModule,
        // FuseSidebarModule,
        // FuseThemeOptionsModule,
        // CategoryNavModule,

        // App modules
        // LayoutModule,
        // AppStoreModule
        ToastrModule.forRoot({
            positionClass: "toast-bottom-right",
            progressBar: true,
            timeOut: 15000,
        }),
    ],
    bootstrap: [MainComponent],
    providers: [
        HermesService,
        Hermes2Service,
        AuthService,
        CanActivateAuthGuard,
        CanLoadAuthGuard,
        { provide: LOCALE_ID, useValue: "es-VE" },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: init_app,
        //     deps: [HermesService],
        //     multi: true,
        // },
        {
            provide: APP_INITIALIZER,
            useFactory: init_app2,
            deps: [Hermes2Service],
            multi: true,
        },
    ],
})
export class MainModule {}
