import {Injectable, Inject, InjectionToken} from '@angular/core';

declare const msgpack5;

/**
 * Crear un metodo autoOff, que apagara la escucha en los eventos, el objetivo de esto es que Angular cada vez que entre a la vista
 * agregara un nuevo Listener sin eliminar los viejos llevanto a events sin necesidad, para ellos crearemos un identificador unico
 * antes de agregar el listener entonces de esta forma todos los listeners agregados a este itentidficador cuando se corra
 * AutoOff se eliminaran todos cuando se desee,
 * */

@Injectable()
export class HermesService {

    // Default reconnect interval
    public reconnectInterval: number = 2;
    private selfClose: boolean = false;
    private _unsubscribeStorage: Map<string, any> = new Map();
    private _events: Map<string, any> = new Map();
    private _config: any = {};
    isOpen: Boolean = false;
    ws: WebSocket;

    private _debug = false;


    constructor() {}

    setInitialization(config): Promise<void> {
        this._config = config;
        // this.ws = new WebSocket(`wss://hermes.techpre.io?token=${config.token}`);
        // this.ws = new WebSocket(`wss://hermes.techpre.io`);
        // this.ws.binaryType = 'arraybuffer';
        // this.ws.onmessage = this.onMessage
        this.connect();
        return new Promise(async (resolve, reject) => {
            // this.ws.onopen = (ms) => {
            //     console.log('OPEN', ms)
            //     this.isOpen = true;
            //
            // };
            // this.ws.onerror = (msg: any) => {
            // console.log(msg)
            //
            // };
            // this.ws.onclose = (msg: any) => {
            //     // this.onClose(msg)
            //     // reject();
            // };
            resolve();

        });
    }

    connect() {
        this.ws = new WebSocket(`wss://hermes.techpre.io?token=${this._config.token}`);
        this.ws.binaryType = 'arraybuffer';
        this.ws.onmessage = this.onMessage.bind(this);

        this.ws.onopen = () => {
            this.isOpen = true;
            console.info('[HERMES]: Connected')
        };
        // this.ws.onerror = this.onError
        this.ws.onclose = this.onClose.bind(this);
    }

    onError(msg) {
        switch (msg.code) {
            // Try and reconnect
            case 'ECONNREFUSED':
                setTimeout(() =>{
                    console.warn('[HERMES] Reconecting');
                    this.connect()
                }, this.reconnectInterval)
                break;

            // Otherwise run error
            default:
                this.isOpen = false;
                console.info(msg)
                console.info('Posiblemente no nos podamos comunicar con el Hermes');
                break;
        }

    }

    onClose(msg) {
        switch (msg.code) {
            // Normal closure
            case 1000:
                this.isOpen = false;
                console.log()
                console.error('[HERMES] Closed ', msg)
                // if (that.debug) {
                //     console.log("[WS]: Closed");
                // }
                break;
            // Unauthorized
            case 401:
                this.isOpen = false;
                console.log()
                console.error('[HERMES] Closed', msg.reason)
                break;
            // Unauthorized
            case 405:
                this.isOpen = false;
                console.log()
                console.error('[HERMES] Closed', msg.reason)
                break;
            // Abnormal closure
            case 1006:
                this.isOpen = false;
                console.log()
                // console.warn('[HERMES] Closed', 'Abnormal closure');
                // setTimeout(() => {
                //     // console.warn('[HERMES] Reconecting');
                //     this.connect()
                // }, this.reconnectInterval)
                break;
            default:
                this.isOpen = false;
                console.log()
                console.error('[HERMES] Closed', 'Code no registred');
                console.log(msg)
                break;
        }
    }

    onMessage(msg) {
        const message = msgpack5().decode(msg.data);

        // TODO: Agregale metodos de reply() y metodos de ack();
        // Emitimos en el evento solicitado
        // this._hermes.emit(message.channel, message);
        // Events.emit(message.channel, message);
        // TODO: Lo que debe ser
        switch (message.type) {
            case 'id':

                // this.id = message.payload.id;
                // console.log('Se DISPARO IP');
                break;
            case 'msg':
                this.emit(message);
                break;
            default:
                // TODO: Si el destinatario del mensaje tiene mi id entonces envio el mensaje a mi
                // TODO: Agregale metodos de reply() y metodos de ack();
                // message.reply = function () {
                //     console.log('mi THIS',this)
                // }

                // message.reply = ({payload}) => {
                //     this.send(Object.assign({}, message, {to: message.from, payload}))
                // }
                // message.ack = () => {
                //
                // }
                // Emitimos en el evento solicitado
                // this.emit(message.channel, message);
                break;
        }
    }

    on(channel, listener, unsubscribe = false): void {
        const id = this._generateUUID();
        // Si el channel existe agregamos un nuevo listener, de lo contrario lo creamos
        if (this._events.has(channel)) {
            const currentEvents = this._events.get(channel);
            // Se agregar el listener, pero con un id para ser eliminado cuando se desee
            currentEvents.push({id, ev: listener});
            this._events.set(channel, currentEvents);
        } else this._events.set(channel, [{id, ev: listener}]);

        if (unsubscribe) this._unsubscribe(channel, id);
    }

    private _unsubscribe(channel, id) {
        if (this._unsubscribeStorage.has(channel)) {
            const currentEvents = this._unsubscribeStorage.get(channel);
            currentEvents.push(id);
            this._unsubscribeStorage.set(channel, currentEvents);
        } else this._unsubscribeStorage.set(channel, [id]);
    }

    unsubscribe() {
        this._unsubscribeStorage
            .forEach((events_ids, channel) => {
                const events = this._events.get(channel)
                    .filter(ev => !events_ids.includes(ev.id));
                this._events.set(channel, events);
            });

        this._unsubscribeStorage = new Map<string, any>();
    }

    emit(data): void {
        // Se buscan los listeners registrados en MAP
        // Tengo que sacar el channel
        const listeners = this._events.get(data.channel)
        listeners.forEach(listener => listener.ev(data))

    }
    /**
     * Cuando se agrege al canal, se debe omitir los mensajes que fueron emitidos por el promio emisor
     * en otras palabras, no queremos escuchar nuestros propios mensajes
     * por lo tanto, se almacenara el mid del mensaje en un array, y si el mensaje coincide dentro del array lo ignoramos
     * e inmediatamente descartamos el mid del array por que sabemos que no recibiremos otro igual.
     * */
    addChannel(channel, listener:any = false): void {
        if (this.isOpen) {
            const message = msgpack5().encode({
                mid: this._generateUUID(),
                channel: channel,
                from: '',
                type: 'channel',
                timestamp: '',
                payload: ''
            });
            this.ws.send(message);
            if (listener) this.on(channel, listener);
        }

    }

    send(msg): void {
        if (this.isOpen) {
            const message = Object.assign({
                mid: this._generateUUID(),
                // channel: '',
                // from: '',
                type: 'msg',
                timestamp: '',
                // payload: payload
            }, msg);
            this.ws.send(msgpack5().encode(message));
        }
    }


// TODO: Falta programar la respuesta
//     reply(): void {
//         console.log(this);
//     }
// TODO: Falta programas el acuse de recibo
//     ack(): void {
//
//     }
    _generateUUID(): string {
        let d = new Date().getTime(); // Timestamp
        let d2 = (performance && performance.now && (performance.now() * 1000)) || 0; // Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            // random number between 0 and 16
            let r = Math.random() * 16;
            // Use timestamp until depleted
            if (d > 0) {
                // tslint:disable-next-line:no-bitwise
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                // Use microseconds since page-load if supported
                // tslint:disable-next-line:no-bitwise
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            // tslint:disable-next-line:no-bitwise
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
}
