import { Injectable } from '@angular/core';
import {Route, CanLoad, RouterStateSnapshot, UrlSegment} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@fuse/services/auth.service';

@Injectable()
export class CanLoadAuthGuard implements CanLoad {

    constructor(
        private authService: JwtHelperService,
        private _authService: AuthService

    ) { }

    canLoad(): boolean {
        // const url = state.url
        // console.log('Child', this.authService.isTokenExpired())
        // If the user is not logged in we'll send them back to the home page
        if (this.authService.isTokenExpired()) {
            this._authService.logout();
            return false;
        }
        return true;
    }
}
