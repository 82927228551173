// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    api: {
        // base: 'https://techpre.io',
        consumers: 'https://erp.techpre.io/consumers',
        // consumers: 'http://144.217.5.170:6002',
        ipam: 'https://erp.techpre.io/ipam',
        // ipam: 'http://144.217.5.170:6000',
        systems: 'https://erp.techpre.io/systems',
        security: 'https://erp.techpre.io/security',
        finances: 'https://erp.techpre.io/finances',
    },
    HERMES_CREDS: `-----BEGIN NATS USER JWT-----
        eyJ0eXAiOiJKV1QiLCJhbGciOiJlZDI1NTE5LW5rZXkifQ.eyJqdGkiOiI1V0NRWE81TExCV0JGNkdTV1pSUEpURUlDRTZYVUFORUFYRDNNSTZLUVM2S0ZCT1AyVFJBIiwiaWF0IjoxNjg0Mzc5MjE1LCJpc3MiOiJBRFg1WkNOS0JZWUJPV1pZQk5ZTU4yTVdUU0FVUzZKRk80SkgzNlFQS0xHNERSRUo3S05ORDdHUSIsIm5hbWUiOiJjbGllbnQiLCJzdWIiOiJVRFFLT09MRUJQWkpSR0hYT0NCNUlRWVpGT1FPT0VZUDQ0U1lXR0w3SUs2U0dRQUg2TVVFVVo1TyIsIm5hdHMiOnsicHViIjp7fSwic3ViIjp7fSwic3VicyI6LTEsImRhdGEiOi0xLCJwYXlsb2FkIjotMSwidHlwZSI6InVzZXIiLCJ2ZXJzaW9uIjoyfX0.jC8rtVGGnHgwwJOERqTPSmPM3pSwe8YDV5sFZyK0TsxTyBojbVCRrWTpjGGuz8CLeQOWObCo2i2e04p_oSrGAg
        ------END NATS USER JWT------
        
        ************************* IMPORTANT *************************
        NKEY Seed printed below can be used to sign and prove identity.
        NKEYs are sensitive and should be treated as secrets.
        
        -----BEGIN USER NKEY SEED-----
        SUAG2URCYPSKTTKEGQFRCEW3IXGQ3NSIJAXS45KPEERVAUCD7NU56KWEQI
        ------END USER NKEY SEED------
        
        *************************************************************
        `
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
