import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { MainModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';
// import { LOCALE_ID } from '@angular/core';
// import es_VE from '@angular/common/locales/es-VE';
// import { registerLocaleData } from '@angular/common';

if (environment.production) {
    enableProdMode();
}
//
// const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule, {
//     providers: [{ provide: LOCALE_ID, useValue: es_VE }]
// });


const bootstrap = () => platformBrowserDynamic().bootstrapModule(MainModule);


// registerLocaleData(es_VE, 'es-VE');

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    }
    else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else {
    bootstrap().catch(err => console.log(err));
}
